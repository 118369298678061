import React from 'react'

import { Link } from 'gatsby-theme-material-ui'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  navItem: {
    display: 'block',
  },
}))

const NavItem = ({ to, label, icon, external, onClick }) => {
  const styles = useStyles()
  const Icon = icon
  const target = external ? '_blank' : '_self'

  return (
    <Link to={to} target={target}>
      <ListItem button onClick={onClick}>
        <ListItemIcon>{icon && <Icon />}</ListItemIcon>
        <ListItemText primary={label} />
      </ListItem>
    </Link>
  )
}

export default NavItem
