import React from 'react'

import { Link } from 'gatsby-theme-material-ui'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  disclaimer: {
    color: '#aaaaaa',
    fontSize: '0.7em',
    textAlign: 'center',
  },
}))

const SpeakPipe = ({}) => {
  const styles = useStyles()
  return (
    <div>
      <iframe
        src="https://www.speakpipe.com/widget/inline/lh4cm4yjyv0ek8khezpwjf5qjyjyy270"
        allow="microphone"
        width="100%"
        height="200"
        frameborder="0"
      >
        <a href="https://www.speakpipe.com/glo4002">
          https://www.speakpipe.com/glo4002
        </a>
      </iframe>
      <div className={styles.disclaimer}>
        Si le widget ne fonctionne pas:{' '}
        <Link to="https://www.speakpipe.com/glo4002" target="_blank">
          SpeakPipe GLO4002
        </Link>
      </div>
      <script
        async
        src="https://www.speakpipe.com/widget/loader.js"
        charset="utf-8"
      ></script>
    </div>
  )
}

export default SpeakPipe
