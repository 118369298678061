import React from 'react'

import clsx from 'clsx'
import moment from 'moment'

import Message, { visibility } from '@announcements/global.md'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  messageBanner: {
    display: 'block',
    backgroundColor: theme.palette.secondary.light,
    margin: theme.spacing(0, -3),
    marginTop: theme.spacing(-3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1, 3),
  },
}))

const MessageBanner = ({}) => {
  const styles = useStyles()

  const isVisible = moment().isBetween(
    moment(visibility.start),
    moment(visibility.end)
  )

  if (!isVisible) {
    return <></>
  }

  return (
    <div className={styles.messageBanner}>
      <Message />
    </div>
  )
}

export default MessageBanner
