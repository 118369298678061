import React from 'react'

import { Link } from 'gatsby-theme-material-ui'

import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    width: '200px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(1),
  },
  label: {
    color: 'white',
  },
}))

const ButtonLink = ({ label, to, external }) => {
  const styles = useStyles()

  const target = external ? '_blank' : '_self'

  return (
    <Link to={to} target={target} className={styles.button}>
      <Typography variant="h6" className={styles.label}>
        {label}
      </Typography>
    </Link>
  )
}

export default ButtonLink
