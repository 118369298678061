import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { Box, Paper, styled } from '@material-ui/core'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import { Highlight } from '@material-ui/icons'

const CritereInfo = ({ type = 'info', children = undefined }) => {
  let icon = <InfoIcon />
  switch (type) {
    case 'info':
      icon = <InfoIcon />
      break
    case 'warning':
      icon = <WarningIcon />
      break
    case 'idea':
      icon = <Highlight />
      break
  }

  return (
    <HighlightedText>
      {icon}
      <Box>
        <MDXProvider>{children}</MDXProvider>
      </Box>
    </HighlightedText>
  )
}

const HighlightedText = styled(Paper)(({ theme }) => ({
  backgroundColor: '#eee',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),

  '& .MuiSvgIcon-root': {
    minWidth: '50px',
  },

  '& p': {
    margin: '0',
  },
}))

export default CritereInfo
