import React from 'react'

import { Link } from 'gatsby-theme-material-ui'

import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs'
import { Home as HomeIcon } from '@material-ui/icons'
import Typography from '@material-ui/core/Typography'

import Routes from '@root/routes'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  breadcrumbs: {
    display: 'block',
  },
}))

const Item = ({ url, children }) => {
  if (url) {
    return <Link to={url}>{children}</Link>
  } else {
    return <Typography>{children}</Typography>
  }
}

const Breadcrumbs = ({ items = [] }) => {
  const styles = useStyles()

  return (
    <div className={styles.breadcrumbs}>
      <MuiBreadcrumbs>
        <Link to={`${Routes.HOME}/`}>
          <HomeIcon data-test-id="home" />
        </Link>

        {items.map((item) => (
          <Item key={item.label} url={item.url}>
            {item.label}
          </Item>
        ))}
      </MuiBreadcrumbs>
    </div>
  )
}

export default Breadcrumbs
