import React, { useState } from 'react'

import Paper from '@material-ui/core/Paper'
import Fab from '@material-ui/core/Fab'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import {
  Event as EventIcon,
  LiveHelp as LiveHelpIcon,
} from '@material-ui/icons'

import { HelpType } from '@components/GetHelpWidget'
import getHelpWidget from '@hooks/getHelpWidget'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  getHelpButton: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    bottom: theme.spacing(11),
    right: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(10),
      right: theme.spacing(4),
    },
  },
  mainButton: {
    alignSelf: 'flex-end',
    height: 72,
    width: 72,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginBottom: theme.spacing(4),
    },
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
  },
  actionLabel: {
    padding: theme.spacing(1, 1.5),
    margin: theme.spacing(0, 1),
  },
  actionButton: {
    margin: theme.spacing(0, 2),
  },
  actionIcon: {
    fontSize: '1.5rem',
    color: theme.palette.common.white,
  },
}))

const GetHelpButton = ({}) => {
  const styles = useStyles()
  const [showActions, setShowActions] = useState(false)
  const widgets = {
    [HelpType.POSER_QUESTION]: getHelpWidget(HelpType.POSER_QUESTION),
    [HelpType.PRENDRE_RDV]: getHelpWidget(HelpType.PRENDRE_RDV),
  }

  const widgetOpen = Object.values(widgets).some((widget) => widget.visible)

  const handleMainButtonClick = () => {
    if (widgetOpen) {
      Object.values(widgets).forEach((widget) => widget.hide())
    } else {
      setShowActions((prev) => !prev)
    }
  }

  const hideActions = () => {
    setShowActions(false)
  }

  const toggleWidget = (helpType) => {
    hideActions()
    widgets[helpType].toggle()
  }

  return (
    <div className={styles.getHelpButton}>
      <Slide direction="up" in={showActions} mountOnEnter unmountOnExit>
        <div className={styles.actions}>
          <div className={styles.action}>
            <Paper className={styles.actionLabel}>
              <Typography variant="button">Poser une question</Typography>
            </Paper>
            <div className={styles.actionButton}>
              <Fab
                size="small"
                color="secondary"
                onClick={() => toggleWidget(HelpType.POSER_QUESTION)}
              >
                <LiveHelpIcon className={styles.actionIcon} />
              </Fab>
            </div>
          </div>
          <div className={styles.action}>
            <Paper className={styles.actionLabel}>
              <Typography variant="button">Prendre rendez-vous</Typography>
            </Paper>
            <div className={styles.actionButton}>
              <Fab
                size="small"
                color="secondary"
                onClick={() => toggleWidget(HelpType.PRENDRE_RDV)}
              >
                <EventIcon className={styles.actionIcon} />
              </Fab>
            </div>
          </div>
        </div>
      </Slide>

      <Fab
        color="primary"
        className={styles.mainButton}
        onClick={handleMainButtonClick}
      >
        <Typography variant="h2">
          <strong>{showActions || widgetOpen ? '✕' : '?'}</strong>
        </Typography>
      </Fab>
    </div>
  )
}

export default GetHelpButton
