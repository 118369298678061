import React from 'react'

import Typography from '@material-ui/core/Typography'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  itemList: {
    counterReset: 'elements',
  },
  item: {
    position: 'relative',
    paddingLeft: theme.spacing(5),
    counterIncrement: 'elements',
    '&:before': {
      content: 'counter(elements)',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translate(0, -50%)',
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
      height: '28px',
      width: '28px',
      lineHeight: '28px',
      textAlign: 'center',
      fontWeight: 'bold',
      borderRadius: '50%',
    },
  },
}))

const EnaVideo = ({ url, quizUrl, videoUrl }) => {
  const styles = useStyles()

  return (
    <div className={styles.itemList}>
      <Typography className={styles.item} variant="body1">
        Visionnez la vidéo qui{' '}
        <a target="_blank" href={url || videoUrl}>
          se trouve ici
        </a>
        .
      </Typography>

      <Typography className={styles.item} variant="body1">
        Répondez aux révisions des connaissances qui{' '}
        {quizUrl ? (
          <a target="_blank" href={quizUrl}>
            se trouvent ici
          </a>
        ) : (
          'se trouvent en bas de la vidéo'
        )}
        .
      </Typography>
    </div>
  )
}

export default EnaVideo
