import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = (align) =>
  makeStyles((theme) => ({
    list: {
      display: 'flex',
      marginTop: '2rem',
      marginBottom: '0',
      justifyContent: align,
      flexWrap: 'wrap',
      '& > *': {
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(4),
      },
    },
  }))()

const HorizontalList = ({ left = false, children }) => {
  const styles = useStyles(left ? 'left' : 'center')

  return <div className={styles.list}>{children}</div>
}

export default HorizontalList
