import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Indicateur from '@components/indicateur';
export const visibility = {
  start: '2021-08-13T02:00:00.000Z',
  end: '2021-08-24T20:00:00.000Z'
};
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  visibility,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Indicateur type="ATTENTION" mdxType="Indicateur">
      <h1>{`Ceci est la version du cours GLO-4002 de la session d'automne 2020.`}</h1>
    </Indicateur>
    <h2>{`Le contenu du cours n'a pas encore été mis à jour pour la session d'automne 2021.`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      