import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  announcementList: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      width: 400,
    },
    justifyContent: 'center',
  },
}))

const AnnouncementList = ({ children }) => {
  const styles = useStyles()

  return <div className={styles.announcementList}>{children}</div>
}

export default AnnouncementList
