import React, { useState, useEffect } from 'react'

import { Link } from 'gatsby-theme-material-ui'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'

import useGetHelpWidget from '@hooks/getHelpWidget'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'

export const HelpType = {
  POSER_QUESTION: 'poser-question',
  PRENDRE_RDV: 'prendre-rdv',
}

const useStyles = makeStyles((theme) => ({
  getHelpWidget: {
    display: 'none',
    position: 'fixed',
    bottom: 0,
    right: theme.spacing(-1.5),
    bottom: theme.spacing(19),
    height: '520px',
    width: '410px',
    zIndex: 1200,
    [theme.breakpoints.up('md')]: {
      bottom: theme.spacing(1),
      right: theme.spacing(14),
    },
  },
  visible: {
    display: 'block',
  },
  frame: {
    position: 'relative',
    height: '520px',
    width: '410px',
    border: 'none',
    zIndex: 1201,
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  loadingSpinner: {
    margin: theme.spacing(2),
  },
  loadingBox: {
    padding: theme.spacing(2),
  },
}))

const GetHelpWidget = ({ helpType }) => {
  const styles = useStyles()
  const { visible } = useGetHelpWidget(helpType)
  const [frame, setFrame] = useState()
  const [loadingFailed, setLoadingFailed] = useState(false)

  const mapWidgetId = (type) => {
    switch (type) {
      case HelpType.POSER_QUESTION:
        return '422813b4-c856-46fc-963a-05c2a40e8880'
      case HelpType.PRENDRE_RDV:
        return '640fcb0a-a4ce-4945-b187-afeab685899f'
    }
  }

  const mapEmail = (type) => {
    switch (type) {
      case HelpType.POSER_QUESTION:
        return 'aide@qualitelogicielle.ca'
      case HelpType.PRENDRE_RDV:
        return 'rendezvous@qualitelogicielle.ca'
    }
  }

  const widgetId = mapWidgetId(helpType)
  const email = mapEmail(helpType)

  useEffect(() => {
    const src = `/groove.xhtml?widgetId=${widgetId}&domain=${document.domain}`
    const frame = <iframe className={styles.frame} src={src}></iframe>

    setFrame(frame)
  }, [])

  useEffect(() => {
    setTimeout(() => setLoadingFailed(true), 10000)
  }, [])

  return (
    <div className={clsx(styles.getHelpWidget, { [styles.visible]: visible })}>
      <div className={styles.loading}>
        {loadingFailed ? (
          <Paper className={styles.loadingBox}>
            <Typography>
              Erreur de chargement de l'outil d'aide.
              <br />
              Si vous bloquez les cookies des parties tierces, cela peut en être
              la cause.
            </Typography>
            <Typography>
              Merci d'autoriser les cookies de parties tierces pour ce site ou
              de nous écrire à <Link to={`mailto:${email}`}>{email}</Link>
            </Typography>
          </Paper>
        ) : (
          <>
            <CircularProgress
              className={styles.loadingSpinner}
              size={72}
              thickness={4}
              color="secondary"
            />
            <Typography>Chargement...</Typography>
          </>
        )}
      </div>

      {frame ? frame : ''}
    </div>
  )
}

export default GetHelpWidget
