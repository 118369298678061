import React from 'react'

import { lieux } from '../data/platforms.yml'

import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import {
  Home as HomeIcon,
  List as ListIcon,
  Assignment as AssignmentIcon,
  VideoCall,
  AccountBalance,
  Link,
  Event as EventIcon,
} from '@material-ui/icons'

import NavItem from '@components/NavItem'

import Routes from '@root/routes'

const DrawerNavigation = ({ items = [] }) => {
  return (
    <List dense>
      <NavItem to={`${Routes.HOME}/`} label="Accueil" icon={HomeIcon} />

      <Divider />

      <NavItem
        to={Routes.CURRICULUM}
        label="À l'étude cette semaine"
        icon={ListIcon}
      />
      <NavItem to={Routes.AGENDA} label="Agenda du cours" icon={EventIcon} />

      <Divider />

      {items.map((item) => {
        const icon = require('@material-ui/icons')[item.nav.icon]

        return (
          <NavItem
            key={item.slug}
            to={`${Routes.PAGES}/${item.slug}`}
            label={item.nav.label}
            icon={icon}
          />
        )
      })}

      <Divider />

      <NavItem to="/discord" label="Discord" icon={AccountBalance} external />
      <NavItem to={lieux.zoom} label="Zoom" icon={VideoCall} external />
      {/*<NavItem*/}
      {/*  to={lieux.projet}*/}
      {/*  label="Projet 2023"*/}
      {/*  icon={AssignmentIcon}*/}
      {/*  external*/}
      {/*/>*/}
      <NavItem to={lieux.monPortail} label="MonPortail" icon={Link} external />
    </List>
  )
}

export default DrawerNavigation
