const Routes = {
  CURRICULUM: '/curriculum',
  HOME: '',
  LECTURES: '/lectures',
  PAGES: '',
  QUESTIONS: '/questions',
  AGENDA: '/agenda',
  DOC: '/documentation',
}

module.exports = Routes
