import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { MDXProvider } from '@mdx-js/react'

const useStyles = makeStyles((theme) => ({
  callout: {
    borderRadius: '4px',
    border: '1px solid ' + theme.palette.border.main,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.light,
  },
}))

/**
 * There is a HUGE gotcha with this component
 * The first line will NOT render as markdown. After years of using tools made
 * by the javascript community, you learn not to even try to understand it. Sorry.
 *
 * Bad (it will render as text with the *, not in bold):
 * <Callout>
 *   **This is bold**, right?
 * </Callout>
 *
 * Good (works as intended):
 * <Callout>
 *
 *   **This is bold**, right?
 * </Callout>
 */
const Callout = ({ children }) => {
  const styles = useStyles()
  return <div className={styles.callout}>{children}</div>
}

export default Callout
