import React from 'react'

import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import TimerIcon from '@material-ui/icons/Timer'
import InfoIcon from '@material-ui/icons/Info'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  indicateur: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(1, 0),
  },
  indicateurIcon: {
    margin: theme.spacing(0.5),
  },
}))

const mapIcon = (type) => {
  switch (type) {
    case 'INFO':
      return InfoIcon
    case 'ATTENTION':
      return WarningIcon
    case 'TEMPS_TRAVAIL':
      return TimerIcon
    // {%- if include.type == "ATTENTION" -%}
    // :warning:
    // {%- elsif include.type == "TEMPS_TRAVAIL" -%}
    // :timer_clock:
    // {%- elsif include.type == "TEMPS_LECTURE" -%}
    // :clock8:
    // {%- elsif include.type == "QUESTION_ETUDE" -%}
    // :interrobang:
    // {%- elsif include.type == "ASTUCE_VITALE" -%}
    // :rescue_worker_helmet:
    // {%- elsif include.type == "MATERIEL_COURS" -%}
    // :man_teacher:
    // {%- endif -%}
  }
}

const Indicateur = ({ type, children }) => {
  const classes = useStyles()
  const Icon = mapIcon(type)

  return (
    <div className={classes.indicateur}>
      <Icon className={classes.indicateurIcon} />
      <Typography>{children}</Typography>
    </div>
  )
}

export default Indicateur
