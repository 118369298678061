import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'

import { Link } from 'gatsby-theme-material-ui'

import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import { Menu as MenuIcon } from '@material-ui/icons'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'

import AnnouncementList from '@components/AnnouncementList'
import HorizontalList from '@components/HorizontalList'
import Announcement, { AnnouncementType } from '@components/Announcement'
import EnaVideo from '@components/EnaVideo'
import Indentation from '@components/Indentation'
import Indicateur from '@components/indicateur'
import Accordion from '@components/Accordion'
import Callout from '@components/Callout'
import CritereGrille from '@components/CritereGrille'
import ButtonLink from '@components/ButtonLink'
import SpeakPipe from '@components/SpeakPipe'
import DrawerNavigation from '@components/DrawerNavigation'
import MessageBanner from '@components/MessageBanner'
import Breadcrumbs from '@components/Breadcrumbs'
import Solution from '@components/solution'
import GetHelpButton from '@components/GetHelpButton'
import GetHelpWidget, { HelpType } from '@components/GetHelpWidget'

import { makeStyles, useTheme } from '@material-ui/core/styles'

import './layout.css'
import CritereInfo from './CritereInfo'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: theme.drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: theme.drawerWidth,
  },
  content: {
    position: 'relative',
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: '80%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
}))

const Layout = ({ breadcrumbs = [], children }) => {
  const data = useStaticQuery(graphql`
    query TitleAndNavItemsQuery {
      site {
        siteMetadata {
          title
        }
      }
      allFile(
        filter: {
          sourceInstanceName: { eq: "pages" }
          ext: { in: [".md", ".mdx"] }
        }
        sort: { fields: childMdx___frontmatter___nav___order }
      ) {
        nodes {
          childMdx {
            frontmatter {
              slug
              nav {
                label
                icon
              }
            }
          }
        }
      }
    }
  `)

  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const navItems = data.allFile.nodes
    .map((item) => item.childMdx.frontmatter)
    .filter((item) => item.nav)

  const drawer = (
    <div>
      <div className={classes.toolbar} />

      <Divider />

      <DrawerNavigation items={navItems} />
    </div>
  )

  const breadcrumbsBlock = (breadcrumbs) => {
    if (breadcrumbs.length) {
      return <Breadcrumbs items={breadcrumbs} />
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <span id="site-title">{data.site.siteMetadata.title}</span>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        <MessageBanner />

        {breadcrumbsBlock(breadcrumbs)}

        {children}
      </main>
    </div>
  )
}

const components = {
  h1: (props) => <Typography variant="h1" {...props} />,
  h2: (props) => <Typography variant="h2" {...props} />,
  h3: (props) => <Typography variant="h3" {...props} />,
  h4: (props) => <Typography variant="h4" {...props} />,
  h5: (props) => <Typography variant="h5" {...props} />,
  h6: (props) => <Typography variant="h6" {...props} />,
  p: (props) => <Typography variant="body1" {...props} />,
  Solution,
  AnnouncementList,
  Announcement,
  HorizontalList,
  EnaVideo,
  Indicateur,
  Accordion,
  Callout,
  Indentation,
  ButtonLink,
  SpeakPipe,
  CritereGrille,
  CritereInfo,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default (props) => (
  <MDXProvider components={components}>
    <Layout {...props} />
  </MDXProvider>
)
