import React from 'react'
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'

const CritereGrille = ({
  code,
  title = undefined,
  weight = 1,
  criteria = [],
  titleVariant = 'h4',
  children = undefined,
}) => {
  const weightInt = parseFloat(weight) !== Number.NaN ? parseFloat(weight) : 1
  return (
    <Box>
      <Typography variant={titleVariant}>Critère {code}</Typography>

      <TableContainer component={Paper}>
        <CriteriaTable size="small">
          <TableHead>
            <TableRow>
              <TableCell>Critère</TableCell>
              <TableCell>Valeur (pts)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {criteria.map((criterion) => (
              <TableRow key={criterion.description}>
                <TableCell>{criterion.description}</TableCell>
                <TableCell>{parseInt(criterion.value) * weightInt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell align="right" colSpan={2}>
                Pondération: x{weight}
              </TableCell>
            </TableRow>
          </TableFooter>
        </CriteriaTable>
      </TableContainer>

      {children}
    </Box>
  )
}

const CriteriaTable = styled(Table)({})

const HighlightedText = styled(Paper)(({ theme }) => ({
  backgroundColor: '#eee',
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacing(2),
  margin: theme.spacing(2, 0),

  '& .MuiSvgIcon-root': {
    minWidth: '50px',
  },
}))

export default CritereGrille
