import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = (quantity) =>
  makeStyles((theme) => ({
    indent: {
      paddingLeft: theme.spacing(quantity),
    },
  }))()

const Indentation = ({ quantity, children }) => {
  const classes = useStyles(quantity)
  return <div className={classes.indent}>{children}</div>
}

export default Indentation
